
.card-top{
    display: flex;
    text-align: center;
    height: 50%;
    padding-top: 3%;
    padding-bottom: 5%;
}

/* ----------------------------top left quadrant------------------------------- */

.landing-left{
    display: flex;
    text-align: center;
    height: 100%;
    width: 50%;
    border-right: solid black 1px;
}

.top-left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.my-card-landing{
    margin: 5%;

    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 50%;

    border: solid  #109037 5px;
    background-color:  #a4e7b8; 

    transition: transform 0.3s ease 0s;
}

.my-card-landing:hover{
    transform: translateY(-10px);
}

.resource-link{
    text-decoration: none;
    color: black;
    font-weight: 500;
}

/* mobile and tablet responsive sizes */

@media only screen and (max-width: 1250px){
    .landing-left{
        display: flex;
        flex-direction: column;
    }

    .landing-external-links{
        display: block;
        text-align: center;
    }

    .my-card-landing{
        margin: auto;
        height: 43%;
    }

    .link-title{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 830px){
    .my-card-landing{
        width: 80%;
    }
}

@media only screen and (max-width: 650px){
    .my-card-landing{
       /* margin-top: 15%;
       margin-bottom: 15%; */
    }
}

@media only screen and (max-width: 450px){
    .link-title{
        font-size: 200%;
    }
}