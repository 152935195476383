.singleProjectBlock{
    text-decoration: none;
    height: 100%;
    width: 92%;
}

.singleProjectBlock:hover{
    text-decoration: none;
}

.carrousel-text{
    display: flex;
    height: 100%;
    width: 100%;
    /* display: inline-block; */
    /* padding-left: 2%; */
}

.card-title{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    margin-left: 0;
    margin-right:auto;
}

.project-blurb{
    margin: auto;
    text-align: right;
    color:  black;
    font-weight: 500;
}

.my-card{
    width: 80%;
    height: 90%;

    display: flex;
    align-items: center;

    border: solid  #109037 5px;
    background-color:  #a4e7b8; 

    transition: transform 0.3s ease 0s;
}

@media only screen and (max-width: 1410px){
    .carrousel-text{
        display: block;
    }
    
    .card-title{
        height: 50%;
        justify-content: center;
    }

    .project-blurb{
        display: flex;
        height: 50%;
        justify-content: center;
        text-align: center;
    }
}


/* @media only screen and (max-width: 767px){

    .card-info{
        text-align: center;
    }

    .card-title{
        margin-left: auto;
        margin-right: auto;
    }

    .project-blurb{
        text-align: center;
    }
} */
