.content {
    /* justify-content: center; */
    /* align-self: center; */
    
    border: black 1px solid;

    /* margin: 5%; */

    height: 100%;
}

/* for all of the background divs */
#root{
    background-color: whitesmoke;
    /* height: 100px; */
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: 5%;
}

/* .horizontalSpace{
    padding: 100px;
}

.verticalSpace{
    padding: 100px;
} */



.my-card:hover{
    transform: translateY(-10px);
    text-decoration: none;
}