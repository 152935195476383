.landing-right{
    width: 50%;
}

.scrolling-project-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.nav-right{
    margin-left: auto;
    margin-right: 1%;
}

.nav-left{
    margin-right: auto;
    margin-left: 1%;
}



@media only screen and (max-width: 1100px){
    .mobile-container{
        height: 90%;
    }

    .scrolling-project-container{
        display: flex;
        justify-content: center;
        align-items: center;
        
    }

    .mobile-buttons{
        display: flex;
        justify-content: center;
    }

    .nav-left{
        margin-right: 25%;
        margin-left: 0;
    }

    .nav-right{
        margin-left: 25%;
        margin-right: 0;
    }
    
}

@media only screen and (max-width: 650px){
    .scrolling-project-container{
        height: 70%;

        margin-top: 22%;
    }

    .card-title{
        font-size: 30px;
    }

    .mobile-buttons{
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media only screen and (max-height: 750px){
    .card-title{
        height: 47%;
    }
}
