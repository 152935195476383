/* Main Card Bottom */
.card-bottom{
    display: flex;
    text-align: center;
    justify-content: space-between;
    padding: 3%;
    height: 50%;
}

.bottom{

    width: 49%;
}

/* -------------static text--------------- */

.static-text{
    font-size: 4vh;

}


@media only screen and (max-width: 1200px){
    .static-text{
        font-size: 300%;
    }
}

@media only screen and (max-width: 1050px){
    .static-text{
        font-size: 200%;
    }
}

@media only screen and (max-height: 700px){
    .email-text{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .static-text{
        font-size: 200%;
    }
}

@media only screen and (max-width: 920px){
    .email-text{
        display: block;
    }
}


@media only screen and (max-width: 800px){
    .static-text{
        font-size: 200%;
    }
}

@media only screen and (max-width: 550px){
    .static-text{
        font-size: 140%;
    }
}

/* -------------Icon Display-------------- */
.single-icon{
    justify-content: center;
    height: 40%;
    width: 22%;
    margin: 1%;
}

.all-tech-icons{
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 1620px){
    .all-tech-icons{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .single-icon{
        width: 45%;
        height: 22%;
    }
}

@media only screen and (max-width: 1700px) {
    
}


@media only screen and (max-width: 820px){
    .single-icon{
        width: 45%;
        height: 22%;
    }
}